<template>
  <div>
    <div class="row">
        <div class="col-md-12">
          <h2 style="border-bottom: 1px solid rgba(34, 42, 66, 0.2); padding-bottom: .5em;">Pontuação</h2>
        </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-lg-4 mb-2">
        <h6>Confira aqui o seu resultado até o momento no desafio gurunews:</h6>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-lg-4 mb-4">
        <div slot="default" class="d-flex">
            <input v-model="searchQuery" type="text" class="form-control align-self-center" id="inlineFormInputGroup" placeholder="apenas números" style="border:1px solid #303F66; background-color: white;">
            <a class="btn btn-md" @click="buscarNumero"><i class="tim-icons icon-zoom-split " style="color:white;" ></i></a>
        </div>
      </div>
    </div>

    <div class="row" >
      <template v-for="campanha in ranking.campanhas">
        <div class="col-md-12 col-lg-4" v-if="ranking.resultados[campanha.id].length > 0">
          <div class="card">
            <div class="card-header">
              <h5 class="card-titulo">{{ campanha.nome}}</h5>
              <span class="card-data">Desafio válido de {{  moment(campanha.dh_criacao).format('DD/MM/YYYY') }} até {{  moment(campanha.dh_limite).format('DD/MM/YYYY') }}</span>
              <div class="pontuacao-usuario" v-if="resultadoUsuario"> 
                <div style="background-color: #F5F6FA; padding: 1em;" v-if="resultadoUsuario[campanha.id]">
                  <span v-html="getPhoneExibir(resultadoUsuario[campanha.id]['participant_phone'])"></span>&nbsp;:&nbsp;
                  <span style="font-weight: bold;">{{ resultadoUsuario[campanha.id]['pontuacao'] }} pontos</span>
                </div>
                <div v-else>
                  <!-- <span v-html="getPhoneExibir(searchQuery)"></span>&nbsp;:&nbsp; -->
                  <span style="font-weight: bold;">Usuário não pontuou.</span>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="table-responsive2">
                <table class="table">
                  <thead class="text-primary">
                    <tr>
                      <!-- <th>#</th> -->
                      <th>Participante</th>
                      <th class="text-center">Gurus</th>
                    </tr>
                  </thead>
                  <!-- {{ ranking.resultados[campanha.id] }} -->
                  <tbody>
                    <tr v-for="(item, index) in ranking.resultados[campanha.id]" :key="item.participant_phone">
                      <!-- <td>
                        {{ index+1 }}
                      </td> -->
                      <td>
                        <div v-html="getPhoneExibir(item['participant_phone'])"></div>
                      </td>
                      <td class="text-center">
                        {{ item['pontuacao']}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
  import config from '@/config';
  // import dayjs from 'dayjs';

  // const MONTHS = [
  //   'January', 'February', 'March', 'April', 'May', 'June',
  //   'July', 'August', 'September', 'October', 'November', 'December'
  // ]


  export default {
    // components: {
    //   dayjs
    // },
    data() {
      return {
        columns: [
          { label: 'Id', field: 'id'},
          { label: 'Título', field: 'titulo'},
          { label: 'Texto', field: 'texto'},
          { label: 'Imagem', field: 'imagem'},
          // { label: 'Status', field: 'status'},
          { label: 'Publicação', field: 'datahora_publicacao'},
          // { label: 'Usuário', field: 'usuario_publicacao'},
        ],
        userdata: {},
        loading: true,
        ranking: [],
        searchQuery: null,
        resultadoUsuario: null,
        searchOptions: {
              enabled: true,
              placeholder: 'Buscar...',
        },
      }
    },
    methods: {
      nova() {
        this.$router.push('/noticias/novo')
      },
      getPhoneExibir(phone) {
        let retorno = phone
        if (phone.search('BR') >= 0) {
          retorno = retorno.replace("BR", "")
          let estado = phone.substring(2,4)
          retorno = retorno.replace(estado, this.getFlag(estado))
        } else {
          retorno = this.getFlag("IN") + retorno
        }
        return retorno
      },
      getFlag(flag) {
        if (flag.length) {
          return '<img src="/img/flags/'+ flag +'.png" alt="'+flag+'"> '
        } else {
          return ''
        }
      },
      buscarNumero() {
        this.searchQuery = this.searchQuery.replace(/\D/g, "");
        
        if (this.searchQuery.length < 8) {
          this.$swal("Ops!", "Número muito curto.<br/> Informe pelo menos 8 dígitos.", "warning");
        }

        this.resultadoUsuario = {}
        this.$aws
        .get('whatsapp/campanha/resultado/'+this.searchQuery)
        .then(response => {
          this.resultadoUsuario = JSON.parse(JSON.stringify(response.data))
        })
        .catch(function (error) {
          console.error(error)
        });   
      }
    },
    mounted() {
      this.$aws
      .get('whatsapp/campanha/resultado')
      .then(response => {
        this.ranking = JSON.parse(JSON.stringify(response.data))
      })
      .catch(function (error) {
        console.error(error)
      });   
    },
  };
</script>
<style lang="scss">
  h3 {
    margin: 0px;
  }
  .table {
    // direction: ltr;
    overflow-x: hidden;
  }
  .card-body {
    height: 40em; 
    width: 100%;
    overflow-x: hidden;
  }
  .card-titulo {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 0px;
  }
  .card-data {
    font-size: .8em;
  }
  // .table-responsive {
  //   scroll
  // }
  /*
  .dataTables_wrapper {
    margin-top: 20px;
    tbody td:last-child {
      text-align: center;
    }
  }
*/
  /* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  aspect-ratio: 1;
  --g: #0000 calc(100% - 5px),#FC7D59 calc(100% - 3px) 93%,#0000;
  background: 
    radial-gradient(farthest-side at bottom,var(--g)) top                  /100% 50%,
    radial-gradient(farthest-side at top   ,var(--g)) bottom 18% right  0  /80%  40%,
    radial-gradient(farthest-side at bottom,var(--g)) left   50% top    30%/60%  30%,
    radial-gradient(farthest-side at top   ,var(--g)) right  34% bottom 37%/40%  20%,
    radial-gradient(farthest-side at bottom,var(--g)) left   52% top    43%/24%  12%;
  background-repeat: no-repeat;
  animation: l20 1s infinite linear;
}
/*
@keyframes l20 {
  100% {transform: rotate(1turn)}
}
  */
</style>
