<template>
  <div>
    <a href="https://convidadoguru.com/gurunews-home">
      <img id="banner-gurubets" src="img/guru/LP_GURUNEWS_MOB.jpg" v-if="mobile">
      <img id="banner-gurubets" src="img/guru/LP_GURUNEWS_DESK.jpg" v-else>
    </a>
  </div>
</template>
<script>
  import config from '@/config';
  export default {
    data() {
      return {
        //Verificação mobile
        mobileAt: 1000, //Mobile a partir de qual width?
        mobile: false, //Status inicial do mobile
      }
    },
    created() {
      //Verificação mobile
      window.innerWidth < this.mobileAt
        ? (this.mobile = true)
        : (this.mobile = false);
    
    },
    methods: {
    },
    mounted() {
    },
  };
</script>
<style lang="scss">
  #banner-gurubets {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
  }
  h3 {
    margin: 0px;
  }
  .table {
    // direction: ltr;
    overflow-x: hidden;
  }
  .card-body {
    height: 40em; 
    width: 100%;
    overflow-x: hidden;
  }
  h5 {
    font-weight: bold;
    font-size: 1.3em;
  }
  // .table-responsive {
  //   scroll
  // }
  /*
  .dataTables_wrapper {
    margin-top: 20px;
    tbody td:last-child {
      text-align: center;
    }
  }
*/
  /* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  aspect-ratio: 1;
  --g: #0000 calc(100% - 5px),#FC7D59 calc(100% - 3px) 93%,#0000;
  background: 
    radial-gradient(farthest-side at bottom,var(--g)) top                  /100% 50%,
    radial-gradient(farthest-side at top   ,var(--g)) bottom 18% right  0  /80%  40%,
    radial-gradient(farthest-side at bottom,var(--g)) left   50% top    30%/60%  30%,
    radial-gradient(farthest-side at top   ,var(--g)) right  34% bottom 37%/40%  20%,
    radial-gradient(farthest-side at bottom,var(--g)) left   52% top    43%/24%  12%;
  background-repeat: no-repeat;
  animation: l20 1s infinite linear;
}
/*
@keyframes l20 {
  100% {transform: rotate(1turn)}
}
  */
</style>
